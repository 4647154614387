import * as React from 'react';
import { Box, Flex, Heading, Text } from '@chakra-ui/react';
import { GatsbyImage } from 'gatsby-plugin-image';
import LinkWrapper from '../LinkWrapper';

function WanaContentCard(props) {
  const { item, route, tagToUse, allTags, inHome } = props;
  
  let tag = null;

  if (!tagToUse) {
    if (!item.iframe) {
      tag = allTags['ARTICLE'];
  
    } else if (item.iframe.startsWith('http')) {
      tag = allTags['VIDEO'];
  
    } else if (!item.iframe.startsWith('http')) {
      tag = allTags['PODCAST'];
  
    }
  }

  return (
    <Box
      w="max-content"
      h="max-content"
      mx={!inHome ? {base:'auto', ms:'0'} : '0'}
      className="fadeIn"
    >
      <LinkWrapper href={`${route}${item?.slug?.current}`}>
        <Box
          w={
            inHome 
              ? {base:'84vw', ssm:'310px', ms:'340px', msx:'30vw', md:'26.4vw', mds:'27vw', lg:'26.4vw', lgs:'26.4vw', xl:'380px'} 
              : {base:'83.34vw', ssm:'300px', ms:'43vw', msx:'41.5vw', md:'42vw', mds:'42vw', lg:'40.5vw', lgs:'40.8vw', xl:'570px'}
          }
          boxShadow="0 10px 34px 0 rgba(0,0,0,0.19)"
          mb={inHome ? {base:'30px', msx:'0'} : {base:'30px'}}
        >
          {/* IMAGE */}
          <Box
            maxW="570px"
            maxH="405px"
            overflow="hidden"
            className={inHome ? 'wana-content-card-home' : 'wana-content-card'}
            bgColor="grayBG"
          >
            <GatsbyImage 
              image={item?.mainImage?.asset?.gatsbyImageData} 
              alt={'wana blog post image'} 
            />
          </Box>

          {/* TEXT */}
          <Box
            minH={
              inHome
                ? {base:'126px', ms:'126px', msx:'116px', md:'116px', mds:'126px', lg:'138px', lgs:'142px', xl:'146px'}
                : {base:'157px', ms:'168px', msx:'120px', md:'122px', mds:'134px', lg:'148px', lgs:'154px', xl:'160px'}
            }
            bgColor="primary"
            p={{base:'15px', ms:'15px', msx:'10px 5px', md:'10px 5px', mds:'15px 10px', lg:'21px 14px', lgs:'23px 16px', xl:'25px 18px'}}
            overflowY="hidden"
          >
            <Box
              maxW="522px"
              mx="auto"
            >
              {/* TAG AND CATEGORY */}
              <Flex
                justifyContent={{base:'center', mds:'flex-start'}}
                alignItems="center"
                mb={{base:'12px'}}
              >
                {/* TAG */}
                <Flex
                  w="85px"
                  justifyContent="center"
                  alignItems="center"
                  bgColor={tagToUse?.tagColor?.color || tag.tagColor.color}
                  mr={{base:'17px', msx:'1.2vw', xl:'17px'}}
                >
                  <Text
                    color="white"
                    fontSize={{base:'11px', md:'11px'}}
                    fontWeight={{base:'bold'}}
                    lineHeight={{base:'12px', md:'12px'}}
                    letterSpacing={{base:'0.92px', md:'0.92px'}}
                    textAlign={{base:'center'}}
                    textTransform="uppercase"
                    p={{base:'5px'}}
                  >
                    {tagToUse?.tag || tag.tag}
                  </Text>
                </Flex>

                {/* SUBCATEGORY */}
                <Text
                  color="white"
                  fontSize={{base:'15px', msx:'10px', md:'11px', mds:'12px', lg:'13px', lgs:'14px', xl:'15px'}}
                  fontWeight={{base:'800'}}
                  lineHeight={{base:'15px', msx:'10px', md:'11px', mds:'12px', lg:'13px', lgs:'14px', xl:'15px'}}
                  letterSpacing={{base:'2.5px', md:'2.5px'}}
                  textAlign={{base:'center'}}
                  textTransform="uppercase"
                >
                  {item.subcategory ? item.subcategory.name : 'category'}
                </Text>
              </Flex>

              {/* TITLE */}
              <Text
                color="white"
                fontSize={
                  inHome 
                    ? {base:'21px'}
                    : {base:'21px', ms:'22px', msx:'23px', md:'24px', mds:'25px', lg:'26px', lgs:'27px', xl:'28px'}}
                fontWeight={{base:'800', md:'800'}}
                lineHeight={
                  inHome
                    ? {base:'1.5'}
                    : {base:'1.5', ms:'32px', msx:'33px', md:'34px', mds:'35px', lg:'36px', lgs:'37px', xl:'38px'}}
                letterSpacing={{base:'-0.24px', md:'-0.24px'}}
                textAlign={{base:'center', msx:'center', md:'center', mds:'left', lg:'left'}}
                noOfLines={{base:3, msx:2, md:2, mds:2, lg:2, lgs:2}}
              >
                {item.title}
              </Text>
            </Box>
          </Box>
        </Box>
      </LinkWrapper>
    </Box>
  );
}

export default WanaContentCard;
