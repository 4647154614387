import * as React from 'react'
import { graphql } from 'gatsby'
import { Box, Text, Flex } from '@chakra-ui/react'
import SeoComponent from '../components/SeoComponent'
// import TabSlider from '../components/TabSlider';
import WanaContentCard from '../components/WanaContentCard'
import Select from 'react-select'
import CTAButton from '../components/CTAButton'

/* SECTIONS */
import Hero from '../sections/Hero'

type objectType = { [x: string]: any }

function Learn(props) {
  const { data } = props

  const heroSection = data.sanityPage.sections.find(item => item?._type === 'hero')
  const seoImg = data.sanityPage.seoImage?.image || heroSection?.heroImg?.bgImage

  const postSubcategories = data.allSanityPostSubcategory.nodes
  const topBarFilter = [{ name: 'show all', slug: { current: 'show-all' } }, ...postSubcategories]
  const subcatsTypes = [
    { slug: { current: 'show-all' }, label: 'SHOW ALL', name: 'show all', index: 0 },
    { slug: { current: 'the-personal' }, label: 'THE PERSONAL', name: 'the personal', index: 1 },
    { slug: { current: 'the-products' }, label: 'THE PRODUCTS', name: 'the products', index: 2 },
    { slug: { current: 'the-plant' }, label: 'THE PLANT', name: 'the plant', index: 3 },
    { slug: { current: 'the-purpose' }, label: 'THE PURPOSE', name: 'the purpose', index: 4 },
  ]
  //const [currentPage, setCurrentPage] = React.useState(topBarFilter[0].name);
  const [currentPage, setCurrentPage] = React.useState(subcatsTypes[0].name)

  const contentTypes = [
    { value: 'all', label: 'SHOW ALL', index: 0 },
    { value: 'video', label: 'VIDEO', index: 1 },
    { value: 'article', label: 'ARTICLE', index: 2 },
  ]

  const [contentTypeSelected, setContentTypeSelected] = React.useState(contentTypes[0].value)

  // const [subcatSelected, setSubcatSelected] = React.useState(subcatsTypes[0].value);

  /* LOAD MORE ITEMS CONTROL */
  const itemsStep = data.allSanityPostsPerPage.nodes[0].totalPosts
  const subcategoriesObj = {}

  const [allItemsLoaded, setAllItemsLoaded] = React.useState(subcategoriesObj)
  const [videoItemsLoaded, setVideoItemsLoaded] = React.useState(subcategoriesObj)
  const [articleItemsLoaded, setArticleItemsLoaded] = React.useState(subcategoriesObj)

  React.useEffect(() => {
    topBarFilter.map((option, indx) => {
      subcategoriesObj[option.name] = itemsStep
    })

    subcatsTypes.map((option, indx) => {
      subcategoriesObj[option.name] = itemsStep
    })

    setVideoItemsLoaded(subcategoriesObj)
    setArticleItemsLoaded(subcategoriesObj)
  }, [])

  const loadMore = () => {
    if (contentTypeSelected === contentTypes[0].value) {
      const allItemsLoadedCopy = { ...allItemsLoaded }
      allItemsLoadedCopy[currentPage] += itemsStep
      setAllItemsLoaded(allItemsLoadedCopy)
    } else if (contentTypeSelected === contentTypes[1].value) {
      const videoItemsLoadedCopy = { ...videoItemsLoaded }
      videoItemsLoadedCopy[currentPage] += itemsStep
      setVideoItemsLoaded(videoItemsLoadedCopy)
    } else if (contentTypeSelected === contentTypes[2].value) {
      const articleItemsLoadedCopy = { ...articleItemsLoaded }
      articleItemsLoadedCopy[currentPage] += itemsStep
      setArticleItemsLoaded(articleItemsLoadedCopy)
    }
  }

  /* POSTS BY TYPE CONTROL */
  const videoTypePosts = data.mediaPosts.nodes
  const videoPostsGroups = data.mediaPosts.group

  const articleTypePosts = data.readPosts.nodes
  const articlePostsGroups = data.readPosts.group

  const [postsToRender, setPostsToRender] = React.useState<any[] | null>(null)

  React.useEffect(() => {
    if (currentPage !== topBarFilter[0].name || currentPage !== subcatsTypes[0].name) {
      setCurrentPage(topBarFilter[0].name)
      setCurrentPage(subcatsTypes[0].name)
    } else {
      if (contentTypeSelected === contentTypes[0].value) {
        setPostsToRender([...videoTypePosts, ...articleTypePosts])
      } else if (contentTypeSelected === contentTypes[1].value) {
        setPostsToRender(videoTypePosts)
      } else if (contentTypeSelected === contentTypes[2].value) {
        setPostsToRender(articleTypePosts)
      }
    }
  }, [contentTypeSelected])

  /* POSTS BY SUBCATEGORY CONTROL */
  React.useEffect(() => {
    //const subcatSelected = topBarFilter.find(item => item.name === currentPage);
    const subcatSelected: objectType | any = subcatsTypes.find(item => item.name === currentPage)

    //if (currentPage === topBarFilter[0].name) {
    if (currentPage === subcatsTypes[0].name) {
      if (contentTypeSelected === contentTypes[0].value) {
        setPostsToRender([...videoTypePosts, ...articleTypePosts])
      } else if (contentTypeSelected === contentTypes[1].value) {
        setPostsToRender(videoTypePosts)
      } else if (contentTypeSelected === contentTypes[2].value) {
        setPostsToRender(articleTypePosts)
      }
    } else {
      if (contentTypeSelected === contentTypes[0].value) {
        const videoGroupPosts: objectType = videoPostsGroups.find(
          item => item.fieldValue === subcatSelected.slug.current
        )
        const articleGroupPosts = articlePostsGroups.find(item => item.fieldValue === subcatSelected.slug.current)

        const allPostsBySub = [] as objectType[]
        videoGroupPosts ? allPostsBySub.push(...videoGroupPosts.nodes) : null
        articleGroupPosts ? allPostsBySub.push(...articleGroupPosts.nodes) : null

        setPostsToRender(allPostsBySub)
      } else if (contentTypeSelected === contentTypes[1].value) {
        const videoGroupPosts = videoPostsGroups.find(item => item.fieldValue === subcatSelected.slug.current)
        setPostsToRender(videoGroupPosts ? videoGroupPosts.nodes : [])
      } else if (contentTypeSelected === contentTypes[2].value) {
        const articleGroupPosts = articlePostsGroups.find(item => item.fieldValue === subcatSelected.slug.current)
        setPostsToRender(articleGroupPosts ? articleGroupPosts.nodes : [])
      }
    }
  }, [currentPage])

  /* GET POSTS LIMIT */
  const getPostsLimit = () => {
    if (contentTypeSelected === contentTypes[0].value) {
      return allItemsLoaded[currentPage]
    } else if (contentTypeSelected === contentTypes[1].value) {
      return videoItemsLoaded[currentPage]
    } else if (contentTypeSelected === contentTypes[2].value) {
      return articleItemsLoaded[currentPage]
    }
  }

  /* GET TAGS DATA */
  const tagsData = {}
  data.allSanityLearnCategory.nodes.map((item, indx) => {
    tagsData[item.tag] = item
  })

  return (
    <>
      <SeoComponent
        title={data.sanityPage.seoTitle}
        description={data.sanityPage.seoDescription}
        imageUrl={seoImg?.asset?.url}
      />

      <Box>
        {/* HERO */}
        <Hero
          header={heroSection.mainHeader.headerText}
          subheader={heroSection.accentHeader.headerText}
          heroBgImg={heroSection.heroImg}
          // heroCta={heroSection.heroCta}
        />

        {/* SELECTORS CONTAINER */}
        <Flex
          maxW={{ base: '90%', msx: '87%', md: '87%', lg: '84%', xl: '1190px' }}
          h='max-content'
          mx='auto'
          mt={{ base: '0px', md: '0px' }}
          mb={{ base: '41px', md: '41px' }}
          justifyContent={{ base: 'center', mds: 'space-between' }}
          alignItems={{ base: 'center', mds: 'flex-start' }}
          position='relative'
          direction={{ base: 'column', mds: 'row' }}
        >
          <div className='selectorsArea'>
            {/* CATEGORY SELECTOR */}
            <Flex
              maxW='278px'
              h='40px'
              alignItems='center'
              mt={{ base: '15px', mds: '0' }}
              mr={{ base: '0', md: '13', lg: '75px', xl: '86px' }}
            >
              <Text
                color='primaryDarker'
                fontSize={{ base: '12px', md: '12px', lg: '13px', xl: '14px' }}
                fontWeight='900'
                lineHeight={{ base: '16px', md: '16px', lg: '17px', xl: '18px' }}
                letterSpacing={{ base: '0.5px', md: '0.5px' }}
                textTransform='uppercase'
                mr={{ base: '20px', md: '5px' }}
              >
                {'Category'}
              </Text>

              <Box border='2px' borderColor='#E3E1ED' w={{ base: '172px', md: '160px', lg: '140px', xl: '140px' }}>
                <Select
                  className='state-dropdown-container'
                  classNamePrefix='state-dropdown'
                  isSearchable={false}
                  defaultValue={subcatsTypes[0]}
                  options={subcatsTypes}
                  onChange={currentPage => setCurrentPage(currentPage.name)}
                  theme={theme => ({
                    ...theme,
                    borderRadius: 0,
                    outline: 'none',
                    outlineWidth: 'transparent',
                    outlineStyle: 'none',
                    colors: {
                      ...theme.colors,
                      neutral0: '#2F2865',
                      neutral90: 'white',
                      neutral70: 'white',
                      neutral80: 'white',
                      neutral50: 'white',
                    },
                  })}
                />
              </Box>
            </Flex>

            {/* POSTS TYPE SELECTOR */}
            <Flex
              maxW='278px'
              h='40px'
              alignItems='center'
              mt={{ base: '15px', mds: '0' }}
              mx='0'
              mb={{ base: '20px', md: '35px', lg: '0px' }}
            >
              <Text
                color='primaryDarker'
                fontSize={{ base: '12px', md: '12px', lg: '13px', xl: '14px' }}
                fontWeight='900'
                lineHeight={{ base: '16px', md: '16px', lg: '17px', xl: '18px' }}
                letterSpacing={{ base: '0.5px', md: '0.5px' }}
                textTransform='uppercase'
                mr={{ base: '20px', md: '5px' }}
              >
                {'Content Type'}
              </Text>

              <Box border='2px' borderColor='#E3E1ED' w={{ base: '145px', md: '135px', lg: '140px', xl: '135px' }}>
                <Select
                  className='state-dropdown-container'
                  classNamePrefix='state-dropdown'
                  isSearchable={false}
                  defaultValue={contentTypes[0]}
                  options={contentTypes}
                  onChange={contentType => setContentTypeSelected(contentType.value)}
                  theme={theme => ({
                    ...theme,
                    borderRadius: 0,
                    outline: 'none',
                    outlineWidth: 'transparent',
                    outlineStyle: 'none',
                    colors: {
                      ...theme.colors,
                      neutral0: '#2F2865',
                      neutral90: 'white',
                      neutral70: 'white',
                      neutral80: 'white',
                      neutral50: 'white',
                    },
                  })}
                />
              </Box>
            </Flex>
          </div>
          {/* <TabSlider 
            w={{base:'100%', ms:'max-content'}}
            currentPage={currentPage}
            topBarFilter={topBarFilter}
            elementID={'learn-subcategories-tabs'}
            objPropertyToShow={'name'}
            setCurrentPage={setCurrentPage}
          /> */}
          <div style={{ textDecoration: 'underline' }}>
            <CTAButton
              href={'/learn/listen/'}
              fontSize={{ base: '18px', msx: '20px' }}
              color={'#2f2865'}
              width={{ base: '45vw', ssm: '290px', msx: '290px' }}
              height={{ base: '48px' }}
              ctaText={'LISTEN TO OUR PODCAST!'}
              _hover={{ bg: 'transparent' }}
            />
          </div>
        </Flex>

        {/* POSTS LIST */}
        {postsToRender && postsToRender.length > 0 && (
          <Flex
            w={{ base: '90%', msx: '87%', md: '87%', lg: '84%', xl: '1190px' }}
            mx='auto'
            mt={{ base: '5.14vw', xl: '74px' }}
            direction={{ base: 'column', ms: 'row' }}
            justifyContent={{ base: 'space-between' }}
            flexWrap={{ base: 'nowrap', ms: 'wrap' }}
          >
            {postsToRender.slice(0, getPostsLimit()).map((post, indx) => {
              return (
                <WanaContentCard
                  key={indx}
                  item={post}
                  route={data.allSanityLearnCategory.nodes[0].postsRoute.route}
                  allTags={tagsData}
                />
              )
            })}

            <Box
              w={{
                base: '83.34vw',
                ssm: '300px',
                ms: '43vw',
                msx: '41.5vw',
                md: '42vw',
                lg: '40.5vw',
                lgs: '40.8vw',
                xl: '570px',
              }}
            />
          </Flex>
        )}

        {/* LOAD MORE */}
        {postsToRender && postsToRender.length >= itemsStep && getPostsLimit() < postsToRender.length && (
          <Flex
            w='148px'
            maxH='48px'
            color='white'
            bgColor='#7FC344'
            mx='auto'
            justifyContent='center'
            alignItems='center'
            mt={{ base: '40px', ms: '50px' }}
            role='button'
            onClick={() => loadMore()}
            _hover={{ bg: '#01A7CF' }}
          >
            <Text
              w='100%'
              fontSize='13px'
              fontWeight='900'
              lineHeight='1'
              letterSpacing='0.5px'
              textAlign='center'
              textTransform='uppercase'
              py='17.5px'
            >
              {'LOAD MORE'}
            </Text>
          </Flex>
        )}

        {/* NO POSTS */}
        {postsToRender && postsToRender.length === 0 && (
          <Box
            w={{ base: '90%', msx: '87%', md: '87%', lg: '84%', xl: '1190px' }}
            mx='auto'
            mt={{ base: '5.14vw', xl: '74px' }}
            borderTop={{ base: '1px' }}
            borderColor='primary'
          >
            <Text color='primary' fontSize='16px' textAlign={{ base: 'center', md: 'left' }}>
              {'There are no posts for this subcategory.'}
            </Text>
          </Box>
        )}
      </Box>
    </>
  )
}

export const query = graphql`
  query {
    sanityPage(slug: { current: { eq: "learn" } }) {
      _id
      slug {
        current
      }
      seoTitle
      seoDescription
      seoImage {
        image {
          asset {
            url
          }
        }
      }
      sections {
        ... on SanityHero {
          _key
          _type
          accentHeader {
            headerText
          }
          mainHeader {
            headerText
          }
          heroImg {
            bgImage {
              asset {
                url
                gatsbyImageData(placeholder: NONE, width: 6000)
              }
            }
            brightness
            contrast
          }
          heroCta {
            _rawTextContent
            textColor {
              color
            }
            bgColor {
              color
            }
            externalLink
            internallLink {
              route
            }
          }
        }
      }
    }
    allSanityPostsPerPage {
      nodes {
        totalPosts
      }
    }
    allSanityLearnCategory {
      nodes {
        tag
        tagColor {
          color
        }
        postsRoute {
          route
        }
        learnRoute {
          route
        }
      }
    }
    allSanityPostSubcategory {
      nodes {
        name
        slug {
          current
        }
      }
    }
    readPosts: allSanityBlogPost(
      filter: { iframe: { eq: null }, isPublic: { eq: true } }
      sort: { fields: _updatedAt, order: DESC }
    ) {
      nodes {
        isPublic
        title
        iframe
        slug {
          current
        }
        subcategory {
          name
          slug {
            current
          }
        }
        allSubcategories {
          name
          slug {
            current
          }
        }
        mainImage {
          asset {
            gatsbyImageData(width: 570, height: 405, fit: MAX, placeholder: NONE)
          }
        }
        resume
      }
      group(field: subcategory___slug___current) {
        fieldValue
        nodes {
          isPublic
          title
          iframe
          slug {
            current
          }
          subcategory {
            name
            slug {
              current
            }
          }
          allSubcategories {
            name
            slug {
              current
            }
          }
          mainImage {
            asset {
              gatsbyImageData(width: 570, height: 405, fit: MAX, placeholder: NONE)
            }
          }
          resume
        }
      }
    }
    mediaPosts: allSanityBlogPost(
      filter: { iframe: { regex: "/^http/" }, isPublic: { eq: true } }
      sort: { fields: _updatedAt, order: DESC }
    ) {
      nodes {
        isPublic
        title
        iframe
        slug {
          current
        }
        subcategory {
          name
          slug {
            current
          }
        }
        allSubcategories {
          name
          slug {
            current
          }
        }
        mainImage {
          asset {
            gatsbyImageData(width: 570, height: 405, fit: MAX, placeholder: NONE)
          }
        }
        resume
      }
      group(field: subcategory___slug___current) {
        fieldValue
        nodes {
          isPublic
          title
          iframe
          slug {
            current
          }
          subcategory {
            name
            slug {
              current
            }
          }
          allSubcategories {
            name
            slug {
              current
            }
          }
          mainImage {
            asset {
              gatsbyImageData(width: 570, height: 405, fit: MAX, placeholder: NONE)
            }
          }
          resume
        }
      }
    }
  }
`

export default Learn
